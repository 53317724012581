import { Overlay } from '@angular/cdk/overlay';
import { NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges,
    OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation,
    inject
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteModule, MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { Category } from '@core/category/category.types';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { ProductService } from '@core/product/product.service';
import { Product } from '@core/product/product.types';
import { fuseAnimations } from '@fuse/animations/public-api';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { debounceTime, filter, map, Subject, takeUntil } from 'rxjs';

/**
 * Represents a search result object that contains a URL, an image URL, and an object of type T.
 * @template T The type of the object contained in the search result.
 */
interface Result<T> {
    /** The URL associated with the search result. */
    url: string;
    /** The image URL associated with the search result. */
    img: string;
    /** The object of type T contained in the search result. */
    object: T;
}

/**
 * Represents a set of search results.
 */
interface ResultSet {
    /**
     * The unique identifier of the result set.
     */
    id: string;
    /**
     * The label of the result set.
     */
    label: string;
    /**
     * The array of search results.
     */
    results: Result<Product | Category>[];
}

/**
 * Component for the search functionality.
 */
@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'qartSearch',
    animations: fuseAnimations,
    standalone: true,
    imports: [
        TranslocoModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatOptionModule,
        RouterModule,
        NgTemplateOutlet,
        MatFormFieldModule,
        MatInputModule
    ],
    providers    : [
        {
            provide   : MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: () =>
            {
                const overlay = inject(Overlay);
                return () => overlay.scrollStrategies.block();
            },
        },
    ],
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {

    /**
     * The appearance of the search input field.
     * 
     * @type {('basic' | 'bar')}
     */
    @Input() appearance: 'basic' | 'bar' = 'basic';

    /**
     * The debounce time for the search input field.
     */
    @Input() debounce: number = 300;

    /**
     * The minimum length of the search query.
     */
    @Input() minLength: number = 1;

    /**
     * The event emitter for the search query.
     */
    @Output() search: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Whether the autocomplete panel is opened or not.
     */
    opened: boolean = false;

    /**
     * The merchant object.
     */
    merchant: Merchant;

    /**
     * The result sets for the autocomplete panel.
     */
    resultSets: ResultSet[] | null;

    /**
     * Whether to show the "See all results" button or not.
     */
    showSeeAllResults: boolean = false;

    /**
     * The search control for the search input field.
     */
    searchControl: UntypedFormControl = new UntypedFormControl();

    /**
     * The subject for unsubscribing from observables.
     */
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * The transloco read key for translations.
     */
    private _translocoRead: string = 'common.search';

    /**
     * Constructor for the SearchComponent class.
     * 
     * @param {MerchantService} _merchantService - The merchant service for getting the merchant object.
     * @param {ProductService} _productService - The product service for getting the products.
     * @param {TranslocoService} _translocoService - The transloco service for translations.
     * @param {ChangeDetectorRef} _changeDetectorRef - The change detector reference for the component.
     * @param {Router} _router - The router for navigating to different routes.
     */
    constructor(
        private _merchantService: MerchantService,
        private _productService: ProductService,
        private _translocoService: TranslocoService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Returns a class list object based on the appearance and opened properties.
     * @returns {Object} - A class list object.
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened
        };
    }

    /**
     * Sets the barSearchInput property to the value of the ElementRef.
     * If the value exists, it means that the search input is now in the DOM and we can focus on the input.
     * @param {ElementRef} value - The value of the ElementRef.
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM and we can focus on the input.
        if (value) {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {
                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Lifecycle hook that is called when any data-bound property of the component is changed.
     * @param changes - An object containing the changed properties and their current and previous values.
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        if ('appearance' in changes) {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * Initializes the component and subscribes to the merchant and search field value changes.
     * When the search field value changes, it filters out undefined/null/false statements and values that are smaller than minLength.
     * It then sends the search value to the server to get the products and displays them in the autocomplete panel.
     * @returns void
     */
    ngOnInit(): void {

        // Get the merchant
        this._merchantService.merchant$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((merchant: Merchant) => {
                this.merchant = merchant;
            });

        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {

                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if (!value || value.length < this.minLength) {
                        this.resultSets = null;
                    }

                    // Continue
                    return value;
                }),
                // Filter out undefined/null/false statements and also
                // filter out the values that are smaller than minLength
                filter(value => value && value.length >= this.minLength)
            )
            .subscribe((value) => {
                if (!this.merchant) {
                    return;
                }
                this.search.next(value);
                const filters: any = {
                    queryString: value.trim(),
                    limit: 6,
                    sort: {
                        field: 'createdAt',
                        order: -1,
                    }
                };
                this._productService.getProducts(filters, {
                        cache: true,
                        concatenateResults: false,
                        propagate: false,
                        forcePropagate: false
                    })
                    .subscribe((products: Product[]) => {
                        // Remove the previous products in the result sets
                        if (this.resultSets) {
                            this.resultSets = this.resultSets.filter((resultSet) => resultSet.id !== 'products');
                        } else {
                            this.resultSets = [];
                        }
                        if (products && products?.length > 0) {
                            if (products.length > 5) {
                                this.showSeeAllResults = true;
                                products = products.slice(0, 5);
                            } else {
                                this.showSeeAllResults = false;
                            }
                            const resultSet: ResultSet = {
                                id: 'products',
                                label: this._translocoService.translate(`${this._translocoRead}.products`),
                                results: products.map((product) => {
                                    const photoUrls: string[] = this._productService.getPhotoUrls(product);
                                    return {
                                        url: `/products/${product.SEO.uri}`,
                                        img: photoUrls.length > 0 ? photoUrls[0] : '',
                                        object: product
                                    }
                                })
                            };
                            this.resultSets.push(resultSet);
                            this.open();
                        }
                    });
            });
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * Unsubscribes from all subscriptions to prevent memory leaks.
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Listens for keyboard events and closes the search if the appearance is 'bar' and the escape key is pressed.
     * @param event The keyboard event.
     */
    onKeydown(event: KeyboardEvent): void {
        // Listen for escape to close the search
        // if the appearance is 'bar'
        if (this.appearance === 'bar') {
            // Escape
            if (event.code === 'Escape') {
                // Close the search
                this.close();
            }
        }
    }

    /**
     * Opens the search component if it's not already opened.
     * @returns void
     */
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Closes the search component.
     * If it's already closed, it returns without doing anything.
     * It clears the search input and marks for check.
     */
    close(): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');

        // Close the search
        this.opened = false;

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Returns a unique identifier for each item in a list.
     * @param index The index of the current item in the list.
     * @param item The current item in the list.
     * @returns The unique identifier for the item.
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    /**
     * Navigates to the URL and closes the search component.
     * @param url The URL to navigate to.
     */
    navigateTo(url: string): void {
        console.log(url);
        this._router.navigate([url]);
        this.close();
    }

    /**
     * Navigates to the products page with the search query parameter and closes the search component.
     */
    seeAllResults(): void {
        this._router.navigate(['/products'], { queryParams: { q: this.searchControl.value } });
        this.close();
    }
}
