import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslocoService } from '@jsverse/transloco';
import { isPlatformBrowser } from '@angular/common';
import { BrowserSupport } from './application.types';


/**
 * Service for retrieving the frontend version number.
 */
@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    /**
   * The version number.
   */
    private _version: BehaviorSubject<string> = new BehaviorSubject(null);

    /**
     * The timer for checking the version number.
     */
    private _checkTimer: NodeJS.Timeout;

    /**
     * Whether the timer has fired.
     */
    private _checkTimerFired: boolean;

    /**
    * The merchant associated with the blogs.
    */
    private _merchant: Merchant;

    /**
     * The name of the Transloco read key.
     */
    private _translocoRead: string = 'application';
    
    /**
     * Creates an instance of ApplicationService.
     * @param _platformId The platform ID.
     * @param _httpClient The HttpClient service.
     * @param _merchantService The MerchantService service.
     * @param _metaTagService The Meta service.
     * @param _deviceService The DeviceDetectorService service.
     * @param _snackBar The MatSnackBar service.
     * @param _translocoService The TranslocoService service.
     */
    constructor(
        @Inject(PLATFORM_ID) private _platformId: any,
        private _httpClient: HttpClient,
        private _merchantService: MerchantService,
        private _metaTagService: Meta,
        private _deviceService: DeviceDetectorService,
        private _snackBar: MatSnackBar,
        private _translocoService: TranslocoService,
    ) {

        this._checkTimer = null;
        this._checkTimerFired = false;

        // Get the merchant
        this._merchantService.merchant$
            .subscribe((merchant: Merchant) => {
                const reload = this._merchant?._id.toString() !== merchant?._id.toString();
                this._merchant = merchant;
                if (reload) {
                    this.getVersion().subscribe();
                }
            });

        const isBrowser: boolean = isPlatformBrowser(this._platformId);
        // If the code is running on the server-side, return immediately
        if (!isBrowser) {
            return;
        }
        const isBrowserSupported: BrowserSupport = this.isBrowserSupported();
        // If the browser is supported, return immediately
        if (isBrowserSupported === BrowserSupport.SUPPORTED) {
            return;
        }
        // Display a permanent message on the screen
        const browser: string = this.getBrowserName();
        const version: string = this.getBrowserVersion();
        console.error("Unsupported browser: " + browser + " " + version);
        const isBrowserTested: boolean = this.isBrowserTested();
        const versionNumber: number = parseInt(this._deviceService.browser_version.split('.')[0], 10);
        const minimumSupportedVersion: number = this.getMinimiumSupportedBrowserVersion();
        let message: string;
        if (!isBrowserTested) { 
            /*
            message = this._translocoService.translate(`${this._translocoRead}.browser-not-tested`, {
                browser
            });
            */
        } else if (versionNumber < minimumSupportedVersion) {
            message = this._translocoService.translate(`${this._translocoRead}.browser-not-supported`, {
                browser,
                version
            });
        }
        setTimeout(() => {
            const snackBarRef = this._snackBar.open(
                message,
                this._translocoService.translate(`${this._translocoRead}.btn-close`),
                { 
                    duration: 0,
                    panelClass: ['snackbar', 'z-50'],
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom'
                }
            );
            snackBarRef.onAction().subscribe(() => snackBarRef.dismiss());
        }, 5000);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for the version number.
     * @returns An observable that emits the version number.
     */
    get version$(): Observable<string> {
        return this._version.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Checks if the version number has changed and reloads the page if necessary.
     * @param version The version number to check against.
     */
    private _checkVersion(version: string): void {
        // Checking for a new version
        if (!version) {
            // No version number provided. Nothing to check.
            return;
        }
        if (this._version.value && version && this._version.value === version) {
            // Nothing to check.
            return;
        }
        // Get the current version number from the metadata of the HTML file
        const metaTag: HTMLMetaElement = this._metaTagService.getTag('name="version"');
        if (!metaTag) {
            // Version meta tag not found on the page.
            if (this._checkTimer) {
                // The retry timer has been set already.
                if (this._checkTimerFired) {
                    // The retry timer has fired already. Proceeding with the update.
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                } else {
                    // The timer hasn't fired yet. Waiting for the timer to fire.
                }
            } else {
                // The retry timer hasn't been set yet. Setting the timer: 10 seconds.
                this._checkTimer = setTimeout(() => {
                    this._checkTimerFired = true;
                    this._checkVersion(version);
                    this._checkTimer = null;
                }, 10000);
            }
        } else {
            // Version meta tag found on the page.
            const currentVersion = metaTag.content;
            // Version installed: " + currentVersion
            // Latest version: " + version
            // If the version number has changed, reload the page
            if (version && version !== currentVersion) {
                console.log("Installing the new version: " + version);
                localStorage.clear();
                sessionStorage.clear();
                window.location.reload();
            } else {
                console.log("Website is up to date: version " + currentVersion + " installed.");
                this._version.next(currentVersion);
            }
        }
    }

    /**
     * Gets the minimum supported version for major browsers
     * See the documentation on supported browsers
     * for Google Maps: https://developers.google.com/maps/documentation/javascript/browsersupport
     * @param browser - The browser name
     * @returns number representing minimum supported version
     */
    private _getMinimumSupportedBrowserVersion(browser: string): number {
        if (browser === 'safari') {
            return 15;
        } else if (browser === 'edge') {
            return 100;
        } else if (browser === 'firefox') {
            return 100;
        } else if (browser === 'chrome') {
            return 100;
        }
        return 0;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Gets the version number of the frontend.
     * @returns An observable that emits the version number.
     */
    getVersion(): Observable<string> {
        if (!environment.ssr) {
            return of('3.3.12');
        }
        if (!this._merchant) {
            return of(null);
        }
        let url = `https://${this._merchant.name}.${environment.qart.domain}/version`;
        if (environment.qart.domain === 'localhost') {
            url = `http://${environment.qart.domain}:4200/version`;
        }
        return this._httpClient.get<{ version: string }>(
            url,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log('Error:', error);
                    return of(null);
                }),
                map((response: { version: string }) => response?.version ? response.version : null),
                tap((version: string) => this._checkVersion(version))
            );
    }

    /**
     * Checks if the current browser is supported to use the website.
     * @returns 
     */
    isBrowserSupported(): BrowserSupport {
        const browser: string = this._deviceService.browser.toLowerCase();
        const version: number = parseInt(this._deviceService.browser_version.split('.')[0], 10);
        const os: string = this._deviceService.os.toLowerCase();
        const isMobile: boolean = this._deviceService.isMobile();
        const isTablet: boolean = this._deviceService.isTablet();
        const isDesktop: boolean = !isMobile && !isTablet;

        console.log("OS name: ", os);
        console.log("OS version: ", this._deviceService.os_version );
        console.log("Browser: ", browser);
        console.log("Browser version: ", version);
        console.log("isMobile: ", isMobile);
        console.log("isTablet: ", isTablet);
        console.log("isDesktop: ", isDesktop);

        // Desktop browsers
        if (isDesktop) {
            const isBrowserVersionSupported: boolean = version >= this._getMinimumSupportedBrowserVersion(browser);
            const versionSupported: BrowserSupport = isBrowserVersionSupported ? BrowserSupport.SUPPORTED : BrowserSupport.NOT_SUPPORTED;
            if (browser === 'edge') {
                if (this._deviceService.browser_version.toLowerCase().includes('ie mode')) {
                    return BrowserSupport.NOT_SUPPORTED;
                } else {
                    return versionSupported;
                }
            } else if (browser === 'firefox' || browser === 'chrome') {
                return versionSupported;
            } else if (browser === 'safari' && os === 'mac') {
                return versionSupported;
            } else {
                return BrowserSupport.UNKNOWN;
            }
        }

        // Mobile browsers
        if (os === 'android') {
            const isBrowserVersionSupported: boolean = version >= this._getMinimumSupportedBrowserVersion(browser);
            const versionSupported: BrowserSupport = isBrowserVersionSupported ? BrowserSupport.SUPPORTED : BrowserSupport.NOT_SUPPORTED;
            if (browser === 'chrome') {
                return versionSupported;
            } else {
                return BrowserSupport.UNKNOWN;
            }
        }

        if (os === 'ios') {
            const isBrowserVersionSupported: boolean = version >= this._getMinimumSupportedBrowserVersion(browser);
            const versionSupported: BrowserSupport = isBrowserVersionSupported ? BrowserSupport.SUPPORTED : BrowserSupport.NOT_SUPPORTED;
            if (browser === 'safari') {
                return versionSupported;
            } else {
                return BrowserSupport.UNKNOWN;
            }
        }

        return BrowserSupport.UNKNOWN;
    }


    /**
     * Checks if the current browser has been tested to use the website.
     * @returns True if the browser has been tested, false otherwise.
     */
    isBrowserTested(): boolean {
        const browser: string = this._deviceService.browser.toLowerCase();
        return browser === 'edge' || browser === 'firefox' || browser === 'chrome' || browser === 'safari';
    }

    /**
     * Returns the name of the current browser.
     * @returns The name of the current browser.
     */
    getBrowserName(): string {
        return this._deviceService.browser;
    }

    /**
     * Returns the version of the current browser.
     * @returns The version of the current browser.
     */
    getBrowserVersion(): string {
        return this._deviceService.browser_version;
    }

    /**
     * Returns the minimum supported version of the current browser.
     * @returns The minimum supported version of the current browser.
     */
    getMinimiumSupportedBrowserVersion(): number {
        return this._getMinimumSupportedBrowserVersion(this._deviceService.browser.toLowerCase());
    }


}