import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { UserService } from '@core/user/user.service';
import { User } from '@core/user/user.types';
import { TranslocoModule } from '@jsverse/transloco';
import { User as FirebaseUser } from 'firebase/auth';
import { Subject, takeUntil } from 'rxjs';

/**
 * User component
 *
 * This component displays the user's avatar and name, and provides a sign-out button.
 */
@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [
        TranslocoModule,
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        RouterModule
    ],
})
export class UserComponent implements OnInit, OnDestroy {

    /**
     * Whether to show the user's avatar
     *
     * @default true
     */
    @Input() showAvatar: boolean = true;

    /**
     * The user object
     */
    user: User;

    /**
     * The URL of the user's avatar
     */
    avatar: string;

    /**
     * Subject that emits when the component has been destroyed.
     */
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     *
     * @param _changeDetectorRef The change detector reference
     * @param _router The router
     * @param _userService The user service
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initializes the component and subscribes to user changes.
     * 
     * @returns void
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async (user: FirebaseUser | null) => {
                if (user) {
                    // We need to wait a bit, otherwise the function gets called as soon as the user is authenticated on Firebase,
                    // but the sign-in procedure did not even have the time to record the access token
                    setTimeout(async () => {
                        this.user = await this._userService.getQartUser();
                        if (user?.photoURL) {
                            this.avatar = user.photoURL;
                        }    
                        // Mark for check
                        this._changeDetectorRef.markForCheck();
                    }, 1000);
                }
            });
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * Unsubscribes from all subscriptions to prevent memory leaks.
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Opens the cookies dialog
     */
    openCookies(): void {
        if (window['openAxeptioCookies']) {
            window['openAxeptioCookies']();
        }
    }

    /**
     * Signs out the user
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }
}
