import { provideEnvironmentInitializer, EnvironmentProviders, inject, Provider } from '@angular/core';
import { IconsService } from 'app/core/icons/icons.service';

/**
 * Icons provider
 */
export const provideIcons = (): Array<Provider | EnvironmentProviders> =>
{
    return [provideEnvironmentInitializer(() => inject(IconsService))];
};
