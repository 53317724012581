import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { Observable } from 'rxjs';

/**
 * TranslocoHttpLoader is a class that implements the TranslocoLoader interface to load translations from a JSON file using HttpClient.
 * @implements {TranslocoLoader}
 */
@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader
{

    /**
     * Inject http client
     */
    private _httpClient = inject(HttpClient);

    /**
     * The path to the JSON file containing the translations
     */
    private _path: string;

    /**
     * Constructor
     */
    constructor() {
        this._path = 'assets/i18n';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get translation
     *
     * @param lang
     */
    getTranslation(lang: string): Observable<Translation>
    {
        return this._httpClient.get<Translation>(`${this._path}/${lang}.json`);
    }
}
