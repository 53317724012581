import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { ApplicationService } from '@core/application/application.service';
import { BrowserSupport } from '@core/application/application.types';


/**
 * Service for managing Google Maps integration.
 */
@Injectable({
    providedIn: 'root'
})
export class GoogleMapsService {

    /**
     * Creates an instance of GoogleMapsService.
     * @param _platformId - The platform ID.
     * @param _document - The document object.
     */
    constructor(
        @Inject(PLATFORM_ID) private _platformId: any,
        @Inject(DOCUMENT) private _document: any,
        private _applicationService: ApplicationService
    ) {
        const isBrowser: boolean = isPlatformBrowser(this._platformId);
        const isBrowserSupported: BrowserSupport = this._applicationService.isBrowserSupported();
        if (!isBrowser || isBrowserSupported === BrowserSupport.NOT_SUPPORTED) {
            return;
        }
        this.loadGoogleMaps();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    
    /**
     * Loads Google Maps.
     */
    loadGoogleMaps(): void {
        // Enable Google Address autocomplete
        const googleMapScript = document.createElement('script');
        googleMapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAtyJHnLBtY-sYJ93EOMV5r7TKehX4A-Hw&libraries=marker,places&language=en';
        googleMapScript.async = true;
        googleMapScript.defer = true;
        googleMapScript.onload = () => {
          console.log('Google Maps API loaded');
        };
        googleMapScript.onerror = (error) => {
          console.warn('Failed to load Google Maps API:', error);
        };
        googleMapScript.type = 'text/javascript';
        this._document.body.appendChild(googleMapScript);
        console.log('Google Maps loaded.');
    }

    
}
