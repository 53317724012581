import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

/**
 * Checks if the user is not authenticated before allowing access to a route.
 * @param route The route being activated.
 * @param state The current router state.
 * @returns An observable that resolves to true if the user is not authenticated, or false otherwise.
 */
export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{

    // Inject router
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((authenticated) =>
        {
            // If the user is authenticated...
            if (authenticated) {
                // Redirect to the specified URL
                router.navigate(['/home']);

                // Prevent the access
                return of(false);
            }

            // Allow the access
            return of(true);
        }),
    );
};