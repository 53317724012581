import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CategoryService } from '@core/category/category.service';
import { Category } from '@core/category/category.types';
import { EmployeeService } from '@core/employee/employee.service';
import { Employee } from '@core/employee/employee.types';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { NavigationService } from '@core/navigation/navigation.service';
import { Navigation } from '@core/navigation/navigation.types';
import { SettingsService } from '@core/settings/settings.service';
import { Settings } from '@core/settings/settings.types';
import { forkJoin, map, Observable, switchMap, tap } from 'rxjs';
import { paths } from './app.paths';
import { GoogleTagManagerService } from '@core/googleTagManager/googleTagManager.service';
import { MondialRelayService } from '@core/mondialRelay/mondialRelay.service';
import { ApplicationService } from '@core/application/application.service';
import { GoogleMapsService } from '@core/googleMaps/googleMaps.service';

/**
 * The InitialResolver class implements the Resolve interface to resolve the initial data for the application.
 * It resolves an array of Navigation, Merchant, Category[], Settings, and Employee[] data by calling multiple API endpoints.
 */
@Injectable({
    providedIn: 'root'
})
export class InitialResolver implements Resolve<[Navigation, Category[], Settings, Employee[], string]> {
    /**
     * Constructor
     * 
     * @param _merchantService The merchant service
     * @param _navigationService The navigation service
     * @param _settingsService The settings service
     * @param _categoryService The category service
     * @param _employeeService The employee service
     * @param _applicationService The application service
     * @param _googleTagManagerService The Google Tag Manager service
     * @param _googleMapsService The Google Maps service
     * @param _mondialRelayService The Mondial Relay service
     */
    constructor(
        private _merchantService: MerchantService,
        private _navigationService: NavigationService,
        private _settingsService: SettingsService,
        private _categoryService: CategoryService,
        private _employeeService: EmployeeService,
        private _applicationService: ApplicationService,
        private _googleTagManagerService: GoogleTagManagerService, // not used directly but needs to be instantiated.
        private _googleMapsService: GoogleMapsService, // not used directly but needs to be instantiated.
        private _mondialRelayService: MondialRelayService // not used directly but needs to be instantiated.
    ) { }

    /**
     * Resolves the initial data for the application
     * 
     * @param route The activated route snapshot
     * @param state The router state snapshot
     * @returns An observable that emits an array of resolved data
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<[Navigation, Category[], Settings, Employee[], string]> {
        return this._merchantService.getMerchant().pipe(
            switchMap((merchant: Merchant) => {
                return forkJoin([
                    this._navigationService.get(),
                    this._categoryService.getCategories(),
                    this._settingsService.getSettings(),
                    this._employeeService.getEmployees(),
                    this._applicationService.getVersion()
                ]);
            })
        );
    }
}


/**
 * This resolver is responsible for resolving the correct path based on a typo in the URL.
 * It uses the Levenshtein distance algorithm to calculate the distance between the typo and the available paths.
 * If the distance is below a certain threshold, it returns the path with the smallest distance.
 * If no paths are found, it returns null.
 */
@Injectable({
    providedIn: 'root'
})
export class SmartPathResolver implements Resolve<string | null> {
    /**
     * Constructor
     */
    constructor(
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Returns the threshold value based on the length of the path.
     * If the path length is less than 5, the threshold value is 3.
     * Otherwise, the threshold value is 5.
     * @param path - The path to check the length of.
     * @returns The threshold value.
     */
    _getThreshold(path: string): number {
        if (path.length < 5) {
            return 3;
        } else {
            return 5;
        }
    }

    /**
     * Sorts an array of strings based on their Levenshtein distance to a given string.
     * @param typoPath The string to compare distances to.
     * @param dictionary The array of strings to be sorted.
     */
    _sortByDistances(typoPath: string, dictionary: string[]): void {
        const pathsDistance: { [name: string]: number } = {};
        dictionary.sort((a, b) => {
            if (!(a in pathsDistance)) {
                pathsDistance[a] = this._levenshtein(a, typoPath);
            }
            if (!(b in pathsDistance)) {
                pathsDistance[b] = this._levenshtein(b, typoPath);
            }
            return pathsDistance[a] - pathsDistance[b];
        });
    }

    /**
     * Calculates the Levenshtein distance between two strings.
     * @param a The first string to compare.
     * @param b The second string to compare.
     * @returns The Levenshtein distance between the two strings.
     */
    _levenshtein(a: string, b: string): number {
        if (a.length === 0) {
            return b.length;
        }
        if (b.length === 0) {
            return a.length;
        }

        const matrix = [];

        // increment along the first column of each row
        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }

        // increment each column in the first row
        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        // Fill in the rest of the matrix
        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1] + 1, // substitution
                        matrix[i][j - 1] + 1, // insertion
                        matrix[i - 1][j] + 1, // deletion
                    );
                }
            }
        }

        return matrix[b.length][a.length];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolves the path for the given route and state by finding the closest match in the paths dictionary.
     * @param route - The activated route snapshot.
     * @param state - The router state snapshot.
     * @returns The resolved path or null if no match is found.
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string | null {
        const typoPath = state.url.replace('/', '');
        const threshold = this._getThreshold(typoPath);
        const dictionary = Object.values(paths)
            .filter(path => Math.abs(path.length - typoPath.length) < threshold);
        if (!dictionary.length) {
            return null;
        }
        this._sortByDistances(typoPath, dictionary);
        return `/${dictionary[0]}`;
    }
}